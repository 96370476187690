<template>
  <div class="content">
    <div class="box1">
      <div class="left">
        <div class="left-title">数据采集</div>
        <div class="left-text">
          现场采集内容覆盖楼宇自控、门禁、智能空调、ups、电梯、
          变配电、消防等系统。其中主要关注核心系统运行状况、主要
          能耗管网状态、环境介质质量监测等数据。将全楼宇的智能控
          制系统的实时状态采集进入系统，供数据监视、存储、报警、
          分析、计算、统计平衡等使用。
        </div>
      </div>
      <div class="right"></div>
    </div>
  </div>
</template>
 
<script>
export default {
  components: {},
  name: "",
  data() {
    return {};
  },

  methods: {},
};
</script>
 
<style scoped lang="scss">
.content {
  padding: 0 16%;
  min-height: calc(100vh - 317px);
  .box1 {
    width: 100%;
    height: 430px;
    display: flex;
    background-color: #fff;
    margin-top: 30px;
    padding: 30px;
    .left {
      width: 50%;
      padding: 5% 10%;
      box-sizing: border-box;
      .left-title {
        font-size: 28px;
        font-weight: 600;
        padding: 30px 0;
        color: #333333;
      }
      .left-text {
        font-size: 16px;
        font-weight: 400;
        color: #6b6b6b;
      }
    }
    .right {
      width: 50%;
      height: 100%;
      background: url("../../../../assets/image/buildingImg/img1.png") no-repeat;
      background-size: 100% 100%;
    }
  }
}
</style>